<template>
  <div class="pc-content">
    <navigation-view :default-selected="1" mode="PRODUCT"></navigation-view>

    <div class="pc-cp-banner">
      <div class="banner"><img src="@/assets/cp-banner.png" alt="" /></div>
    </div>

    <div class="cp-xq-box">
      <div class="cpxq-tit"><h1>服务功能详情</h1></div>
      <div class="cpxq-js">
        <div class="cpxq-js-l">
          <img :src="productData.dpLogoUrl" alt="" />
        </div>
        <div class="cpxq-js-r">
          <h1>{{ productData.dpName }}</h1>
          <p>
            {{ productData.dpDetailed }}
          </p>
        </div>
        <div class="cpxq-js-rwm">
          <div class="ewm">
            <q-r-code :text="downloadURL()"></q-r-code>
            <h2>手机扫一扫下载</h2>
          </div>
        </div>
      </div>
      <el-carousel :interval="5000" arrow="always" type="card" class="cpxq-jt-list-w" ref="banner">
        <el-carousel-item v-for="item in productData.dpPreViewImageUrl" :key="item">
          <img :src="item" :style="{'height': 200}">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="xx-info-box">
      <div class="xxinfo-cnt">
        <div class="xxinfo-l">
          <div class="info-col">
            <h1>联系方式</h1>
            <p>{{ productData.dpContactTel }}</p>
          </div>
          <div class="info-col">
            <h1>工作时间</h1>
            <p>上午9:00~下午17:30</p>
          </div>
          <div class="info-col">
            <a
              @click="showAgreement(productData.dpUserAgreement, '用户协议')"
              class="xy-lj"
              >用户协议</a
            >
          </div>
        </div>
        <div class="xxinfo-r">
          <div class="info-col">
            <h1>公司邮箱</h1>
            <p>{{ productData.dpContactEmail }}</p>
          </div>
          <div class="info-col">
            <h1>版权所有者</h1>
            <p>{{productData.dpCopyrightStatement}}</p>
          </div>
          <div class="info-col">
            <a
              @click="showAgreement(productData.dpPrivacyAgreement, '隐私协议')"
              class="xy-lj"
              >隐私协议</a
            >
          </div>
        </div>
      </div>
    </div>

    <footer-view />

    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="80%"
      height="100%"
      destroy-on-close
      center
    >
      <iframe
        :src="dialogUrl"
        frameborder="0"
        width="100%"
        height="600px"
      ></iframe>
    </el-dialog>
  </div>
</template>


<script>
import NavigationView from "@/components/NavigationView";
import OfficialWebsite from "@/api/OfficialWebsite";
import FooterView from "@/components/FooterView";
import QRCode from "vue-qrcode-component";
export default {
  name: "index",
  components: { NavigationView, FooterView, QRCode },
  data() {
    return {
      dialogTitle: "",
      dialogVisible: false,
      dialogUrl: "",
      productData: {},
    };
  },
  created() {
    this.queryProduct();
    window.scroll(0, 0);
  },
  methods: {
    downloadURL() {
      const dpId = this.$route.query["dpId"]
      var domain = window.location.host
      var protocol = window.location.protocol
      return `${protocol}//${domain}/#/Download?dpId=${dpId}`
    },
    showAgreement(url, title) {
      this.dialogTitle = title
      this.dialogUrl = url
      this.dialogVisible = true
    },
    queryProduct() {
      const dpId = this.$route.query["dpId"];
      OfficialWebsite.queryProduct(dpId).then(({ data }) => {
        this.productData = data
        this.$nextTick(() => { 
          this.$refs['banner'].setActiveItem(0)
        })
      });
    },
  },
};
</script>

<style scoped>
@import "../../../css/reset.css";
@import "../../../css/main.css";
.cpxq-jt-list-w :deep(img) { width: 100%;}
</style>