import { $emit } from "vue-happy-bus";
import Constant from "@/utils/constant";
import { showToast } from "vant";
import common from "./common";
import DSBridge from "dsbridge"

const BRIDGE_ERROR_MESSAGE = '打开任务失败！请更新应用至最新版本~'
export default class {

    static init() {
        if (common.isIOS()) {
            DSBridge.register('XMService', {
                tag: 'XMService',
                rewardPointsUpdate: () => {
                    $emit(Constant.REWARD_POINTS_UPDATE)
                } 
            })
        } else {
            window.XMResponseAndroidService = new XMResponseAndroidService()
        }
        
    }

    static taskExecute(taskData) {
        try {
            let data = JSON.stringify(taskData)
            if (common.isIOS()) {
                DSBridge.call("XMService.taskExecute", data)
            } else {
                window.XMRequestAndroidService.taskExecute(data)
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static closePage() {
        try {
            if (common.isIOS()) {
                DSBridge.call("XMService.closePage", "1")
            } else {
                window.XMRequestAndroidService.closePage()
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static showQRCodeManage(title, content, url, codeUrl) {
        try {
            if (common.isIOS()) {
                DSBridge.call("XMService.showQRCodeManage", JSON.stringify({'QRCodeURL': codeUrl, "title": title, "content": title}))
            } else {
                window.XMRequestAndroidService.showQRCodeManage(title, content, url)
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static exchangeVipSuccess() {
        try {
            if (common.isIOS()) {
                DSBridge.call("XMService.exchangeVipSuccess", "1")
            } else {
                window.XMRequestAndroidService.exchangeVipSuccess()
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static signSuccess() {
        try {
            if (common.isIOS()) {
                DSBridge.call("XMService.signSuccess", "1")
            } else {
                window.XMRequestAndroidService.signSuccess()
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static openMiniProgram(config) {
        try {
            let data = JSON.stringify(config)
            if (common.isIOS()) {
                DSBridge.call("XMService.openMiniProgram", data)
            } else {
                window.XMRequestAndroidService.openMiniProgram(data)
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static SDKInfo() {
        try {
            if (common.isIOS()) {
                let info = DSBridge.call("XMService.getFixedVersionCode", "1")
                let json = JSON.parse(info)
                return json
            } else {
                let info = window.XMRequestAndroidService.getFixedVersionCode()
                let json = JSON.parse(info)
                return json
            }

        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
            return {}
        }
    }

    static copyClipboard(str) {
        try {
            window.XMRequestAndroidService.copyClipboard(str)
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static isLogin(isOpenLogin) {
        try {
            if (common.isIOS()) {
                let info = DSBridge.call("XMService.isLogin", "1")
                let json = JSON.parse(info)
                return json
            } else {
                let info = window.XMRequestAndroidService.isLogin(isOpenLogin)
                let json = JSON.parse(info)
                return json
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static getUserToken(security = true) {
        try {
            if (common.isIOS()) {
                let userToken = DSBridge.call("XMService.getUserToken", "1")
                return userToken
            } else {
                let userToken = window.XMRequestAndroidService.getUserToken()
                return userToken
            }
        } catch (e) {
            if (security) {
                showToast(BRIDGE_ERROR_MESSAGE)
            } else {
                throw e;
            }
        }
    }

    static getAppId(security = true) {
        try {
            if (common.isIOS()) {
                let appId = DSBridge.call("XMService.getAppId", "1")
                return appId
            } else {
                let appId = window.XMRequestAndroidService.getAppId()
                return appId
            }
        } catch (e) {
            if (security) {
                showToast(BRIDGE_ERROR_MESSAGE)
            } else {
                throw e;
            }
        }
    }

    static getPlatformName(security = true) {
        try {
            if (common.isIOS()) {
                let platformName = DSBridge.call("XMService.getPlatformName", "1")
                return platformName
            } else {
                let platformName = window.XMRequestAndroidService.getPlatformName()
                return platformName
                
            }
        } catch (e) {
            if (security) {
                showToast(BRIDGE_ERROR_MESSAGE)
            } else {
                throw e;
            }
        }
    }

    static getIsShowAd(security = true) {
        try {
            if (common.isIOS()) {
                let platformName = DSBridge.call("XMService.isShowAd", "1")
                return platformName
            } else {
                let platformName = window.XMRequestAndroidService.isShowAd()
                return platformName
                
            }
        } catch (e) {
            if (security) {
                // Toast(BRIDGE_ERROR_MESSAGE)
            } else {
                throw e;
            }
        }
    }

    static toLogin() {
        try {
            window.XMRequestAndroidService.toLogin()
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }
 
    static setLongClickToSave() {
        try {
            if (common.isIOS()) {
                DSBridge.call("XMService.setLongClickToSave", "1")
            } else {
                window.XMRequestAndroidService.setLongClickToSave()
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static setLongClickToShareAndSave(title, content, url) {
        try {
            if (common.isIOS()) {
                DSBridge.call("XMService.setLongClickToShareAndSave", "1")
            } else {
                window.XMRequestAndroidService.setLongClickToShareAndSave(title, content, url)
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

    static hideTitleBar() {
        try {
            if (common.isIOS()) {
                DSBridge.call("XMService.hideTitleBar", "1")
            } else {
                window.XMRequestAndroidService.hideTitleBar()
            }
        } catch (e) {
            showToast(BRIDGE_ERROR_MESSAGE)
        }
    }

}
export class XMResponseAndroidService {
    rewardPointsUpdate() {
        $emit(Constant.REWARD_POINTS_UPDATE)
    }
}

